import { configureStore } from '@reduxjs/toolkit';
import schedulerReducer from '../components/CronJobs/store';
import frbReducer from '../components/FRB/store';
import gameReducer from '../components/GameIframe/store';
import gameListReducer from '../components/GameList/store';
import modalReducer from '../components/Modal/store';
import playerReducer from '../components/Player/store';
import serverInfoReducer from '../components/ServerInfo/store';
import walletsReducer from '../components/Wallet/store';
import tournamentReducer from '../components/WinnerList/store';

export const store = configureStore({
  reducer: {
    player: playerReducer,
    games: gameListReducer,
    game: gameReducer,
    tournament: tournamentReducer,
    modal: modalReducer,
    wallets: walletsReducer,
    frb: frbReducer,
    scheduler: schedulerReducer,
    serverInfo: serverInfoReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
