import React from 'react';
import ReactJson from 'react-json-view';
import { useAppDispatch, useAppSelector } from '../../store';
import { fetchServerInfo } from './store';

function ServerInfo() {
  const { info } = useAppSelector((state) => {
    const { info } = state.serverInfo;

    return {
      info,
    };
  });

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchServerInfo());
  }, []);

  return (
    <div className="container text-start">
      <ReactJson
        displayDataTypes={false}
        src={info}
        theme="pop"
        enableClipboard={false}
        indentWidth={10}
        name={false}
        sortKeys
        collapsed={false}
      />
    </div>
  );
}

export default ServerInfo;
