import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { httpClient } from '../../lib/fetch';

export const fetchServerInfo = createAsyncThunk('server-info', async () => {
  const info = await httpClient.get<Record<string, unknown>>('/api/server/info');
  return info;
});

const initialState = {
  info: {},
};

const serverInfoSlice = createSlice({
  name: 'serverInfo',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchServerInfo.fulfilled, (state, action) => {
      return {
        ...state,
        info: action.payload,
      };
    });
  },
});

export default serverInfoSlice.reducer;
