import React, { ChangeEventHandler, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { useAppDispatch, useAppSelector } from '../../store';
import Modal from '../Modal';
import { openModal } from '../Modal/store';
import { getGameThumbnail } from './GameListCarousel';
import { fetchGames, selectGames } from './store';
import { IGame, TParam } from './types';

type TGamesProps = {
  params?: TParam;
};

export const gameOrderList: { projectCode: string; id: string; code: string; aliasName?: string }[] = [
  { projectCode: 'XT-005S', id: '824e4ff8-2d9e-4655-9966-342b12402455', code: 'xt-005s-billfish-riches' }, // BILLFISH RICHES
  { projectCode: 'XT-004S', id: 'e3665667-fbf8-4ca1-801d-f900520ba3a9', code: 'xt-004s-mystic-snowflakes' }, // MYSTIC SNOWFLAKES
  { projectCode: 'XT-003S', id: '7594caf8-06f0-4f88-8162-a320470c4a23', code: '' }, // IZAKAYA
  { projectCode: 'XT-002S', id: 'db8f3359-8589-45cc-9409-d7b5c6801764', code: 'xt-002s-pachinca' }, // PACHINCA
  { projectCode: 'XT-001S', id: 'a7c653c1-d9cb-4e7b-982e-6edde5d38760', code: 'xt-001s-dazzling-box' }, // DAZZLING BOX
  { projectCode: 'P7-051S', id: '76e0435e-3c1d-4a83-ab9e-36700ca2b646', code: 'p7-051s-mrfirst-bookofwaifu' }, // Mr. First BOOK of WAIFU
  { projectCode: 'P7-050S', id: '7b3cc7d8-1a6a-41fd-ae57-dae5c4c80bd1', code: 'p7-050s-pachinca-dice' }, // PACHINCA DICE
  { projectCode: 'P7-049S', id: '204466d9-f59c-44a1-869a-4e28f9d7cf1f', code: 'p7-049s-the-wild-four-dice' }, // THE WILD FOUR DICE
  { projectCode: 'P7-048S', id: '2f241e18-db32-406d-a6ba-1b909e8555c0', code: 'p7-048s-golden-pirate-dice' }, // GOLDEN PIRATE DICE
  { projectCode: 'P7-047S', id: '1c00895e-f7c2-4fcd-b6ad-b680386d23b3', code: 'p7-047s-sakura-fantasia-dice' }, // SAKURA FANTASIA DICE
  { projectCode: 'P7-046S', id: '26b7b220-15eb-4857-9d19-70d4171972f3', code: 'p7-046s-alice-and-wonder-mirror' }, // Alice and wonder Mirror
  { projectCode: 'P7-042S', id: 'dabe3cb6-c28d-41ad-8bba-1d54f82567fc', code: 'p7-042s-dice-of-chaos' }, // METEOR OF CHAOS DICE
  { projectCode: 'P7-041C', id: '9ca96cf8-2eb1-40db-94eb-f900da2030c6', code: 'p7-041c-rally-rise' }, // RALLY RISE
  { projectCode: 'P7-039S', id: 'c34e26f0-9947-4eec-950e-fd6208a26eed', code: 'p7-039s-minecarts-gold' }, // MINECARTS GOLD
  { projectCode: 'P7-038S', id: 'b40dd6ff-3c2e-4cba-9b9f-25a187e39d13', code: 'p7-038s-disco' }, // DISCO
  { projectCode: 'P7-037S', id: 'd0980ee6-51c4-428d-8491-412da8ff2550', code: 'p7-037s-mrfirst-sakura' }, // MR. FIRST SAKURA FANTASIA
  { projectCode: 'P7-036S', id: 'd811ce51-1226-4025-87bf-48a9f64afc50', code: 'p7-036s-winter-guardian' }, // WINTER GUARDIANS
  { projectCode: 'P7-034S', id: '2ff24482-057f-4820-8bb1-b2d6e30961b7', code: 'p7-034s-book-of-odin' }, // BOOK OF ODIN
  { projectCode: 'P7-035S', id: '3ca45482-cc8d-4d4b-9572-0ef365304fd6', code: 'p7-035S-hollywood-wonders' }, // HOLLYWOOD WONDERS
  { projectCode: 'P7-033S', id: 'ae86071a-7039-442f-992c-79f4dd1a37dd', code: '' }, // KRAKEN CLASH
  { projectCode: 'P7-032S', id: 'f5db11c4-c937-4373-8984-182b587c6747', code: 'p7-032s-electro-sweeper' }, // ELECTRO SWEEPER
  { projectCode: 'P7-031S', id: '6a031dd1-89bf-454e-893e-e0e6986fea70', code: 'p7-031s-onsen-fortune' }, // ONSEN FORTUNE
  { projectCode: 'P7-030S', id: '508bb990-dac3-4cab-ba15-6789391a5e85', code: 'p7-030s-naughty-joker' }, // NAUGHTY JOKER
  { projectCode: 'P7-029S', id: '09bf2f76-f14f-43db-8630-6e17cc0dd6f2', code: '' }, // WOLF and SHEEP
  { projectCode: 'P7-028S', id: '6d617669-616e-4077-7265-617468e7c4be', code: 'p7-028s-mavian-wreath' }, // MAVIAN WREATH
  { projectCode: 'P7-027S', id: 'f96a4886-a701-41a7-9c90-f248003de888', code: 'p7-027s-age-of-explorers' }, // AGE OF EXPLORERS
  { projectCode: 'P7-053S', id: '491b1ce3-3b86-445e-91fb-a9fa4cfbf077', code: 'p7-053s-mr-first-age-of-explorers' }, // mr.First AGE OF EXPLORERS
  { projectCode: 'P7-026S', id: '643ffb38-a83c-4af3-aaec-fc4928a88321', code: 'p7-026s-shibas-birthday' }, // SHIBA'S PARTY
  { projectCode: 'P7-025S', id: 'a541fd31-6ee1-4837-8420-ec5927dd6303', code: '' }, // HIT THE MOLE
  { projectCode: 'P7-024S', id: '80d4ecd9-f75e-48f8-9147-ac51fedbb74e', code: '' }, // BUZZING BEES
  { projectCode: 'P7-023S', id: '6906750c-f4ee-4602-80cb-470b853699fb', code: '' }, // TOMATO FESTIVAL
  { projectCode: 'P7-022S', id: 'ab23256b-992d-4bd0-8089-4aa1d560797b', code: '' }, // SAKURA FANTASIA
  { projectCode: 'P7-021S', id: '435c7b5b-0f88-43d7-bb87-a1ce603b8fa4', code: 'p7-021s-fujin-raijin' }, // FUJIN and RAIJIN
  { projectCode: 'P7-020S', id: '680ba83d-9c72-4bac-a465-9b4966716350', code: '' }, // FLUFFY STAR
  { projectCode: 'P7-019S', id: 'b5c2bbc3-2d56-4a4e-a411-fd16922d68c2', code: 'p7-019s-dice-fantasy' }, // DICE FANTASY
  { projectCode: 'P7-018S', id: '92d46cfe-6399-4132-9246-643d36ad4b7e', code: 'p7-018s-bookofwaifu' }, // BOOK OF WAIFU
  { projectCode: 'P7-017S', id: '1f3d26dd-e3f5-4e7c-8b32-b618e335377d', code: 'p7-017s-azai' }, // AZAI SISTERS
  { projectCode: 'P7-016S', id: '812327ed-d1fe-4654-b7c6-8fc020b91325', code: 'p7-016s-cardmaster' }, // THE CARDMASTER
  { projectCode: 'P7-015S', id: 'e2187e05-b4bb-4d6b-8c88-c3fd4f03c083', code: 'p7-015s-dragon' }, // AWAKENING OF THE DRAGON
  { projectCode: 'P7-014S', id: 'eb66909f-c5c5-4b39-aa20-311cf6952caf', code: 'p7-014s-pachicroon' }, // PACHI CROON
  { projectCode: 'P7-013S', id: 'ef804251-8f17-443e-ac4e-7f42c9b1caae', code: 'p7-013s-mega-heist' }, // SPIN HEIST
  { projectCode: 'P7-012S', id: '45069551-28bb-4af5-8965-2dea77488f3e', code: 'p7-012s-hammer-thunder' }, // HAMMER & THUNDER
  { projectCode: 'P7-011S', id: 'f93898ef-5635-4276-af71-c417173572da', code: 'p7-011s-african-animals' }, // AFRICAN ANIMALS
  { projectCode: 'P7-010S', id: '1560c13f-4707-494d-922b-b49dd1dfacf1', code: 'p7-010s-samurai-rumble' }, // SAMURAI RUMBLE
  { projectCode: 'P7-009S', id: '9776a55d-ac49-401b-9a9e-691b4b2c7683', code: 'p7-009s-meteor-of-chaos' }, // METEOR OF CHAOS
  { projectCode: 'P7-008S', id: '18f3ab70-4155-401c-862d-7c5099e692f6', code: 'p7-008s-ninja-strike' }, // NINJA STRIKE
  { projectCode: 'P7-007S', id: '41a1ac68-f793-4b0b-b833-0c68e5c431c7', code: 'p7-007s-wild-four' }, // THE WILD FOUR
  { projectCode: 'P7-006S', id: 'ae830e1b-268f-4154-aabd-46732a66d99b', code: 'p7-006s-dual-fusion' }, // LUCKY DUAL FUSION
  { projectCode: 'P7-005S', id: 'eba7f94f-c50c-493d-bedb-476e41061b8a', code: 'p7-005s-paradise-delight' }, // PARADISE DELIGHT
  { projectCode: 'P7-004S', id: '77b701b7-5210-4cfa-9327-d6d3ab9f3317', code: 'p7-004s-golden-pirate' }, // GOLDEN PIRATE
  { projectCode: 'P7-003S', id: '977a152e-188e-49f8-b075-4c2c51f5fb30', code: 'p7-003s-shark-rush' }, // SHARK RUSH
  { projectCode: 'P7-002S', id: '5a6aacac-18c4-4a03-9457-98544dd385c6', code: 'p7-002s-forbidden-pyramid' }, // THE FORBIDDEN PYRAMID
  { projectCode: 'P7-001S', id: 'e98ecbf6-03ea-4ada-9e15-3da6d85fc257', code: 'p7-001s-mai-tai-punch' }, // MAI TAI PUNCH
  { projectCode: 'BG-001S', id: '39573239-f13c-4a31-8a9e-f8dc5a010ab1', code: 'bg-001s-steampunk' }, // STEAMPUNK(TBD)
];

const sortGames = (games: IGame[]) => {
  const sortGames: IGame[] = gameOrderList.map((e) => {
    const game = games.find((game) => game.gameId === e.id);
    if (game) {
      return game;
    }
    const notAssignGame: IGame = {
      urlPrefix: '',
      gameId: e.id,
      gameName: e.aliasName!,
      gameTypeId: '',
      demoGameAvailable: false,
      freeSpinAvailable: false,
      isDesktop: false,
      isMobile: false,
      tournament: false,
    };
    return notAssignGame;
  });
  return sortGames;
};

export default function GameList(props: TGamesProps) {
  const dispatch = useAppDispatch();
  const { games } = useAppSelector(selectGames);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const session = useSessionContext();
  const [searchParams, setSearchParams] = useSearchParams({ q: '' });
  const queryText = searchParams.get('q') ?? '';
  const filterText = queryText.toLowerCase().replaceAll(' ', '');
  const inputRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (props?.params || !games.length) {
      dispatch(fetchGames(props.params));
    }
  }, []);

  const onClick = React.useCallback((item: IGame) => {
    if (session.loading) {
      return;
    }
    if (item.tournament && session.userId) {
      return dispatch(openModal(item.gameName));
    } else {
      return navigate(`/play/${item.gameId}`);
    }
  }, []);

  const okButton = React.useCallback(
    (item: IGame) => {
      return navigate(`/play/${item.gameId}`);
    },
    [session.loading],
  );

  const filterTextHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    const query = e.target.value;
    setSearchParams(query ? { q: query } : {}, { replace: !!queryText });
  };

  React.useEffect(() => {
    if (inputRef.current) {
      if (inputRef.current.value !== queryText) {
        inputRef.current.value = queryText;
      }
    }
  }, [queryText]);

  const sortedGames = sortGames(games);

  const filteredGamesFromProjectCode = sortedGames.filter((game) => {
    const findGame = gameOrderList.find((e) => e.id === game.gameId);
    if (findGame && findGame.projectCode && findGame.projectCode.toLocaleLowerCase().includes(filterText)) {
      return findGame;
    }
    return null;
  });

  const filteredFromGameName = sortedGames.filter(
    (game) => game && game.gameName && game.gameName.toLowerCase().replaceAll(' ', '').includes(filterText),
  );

  const filteredGames = !filterText
    ? sortedGames
    : Array.from(new Set([...filteredFromGameName, ...filteredGamesFromProjectCode]));

  return (
    <div id="game" className="casino">
      <h1 className="game-title">{t('phoenixGames')}</h1>
      <div></div>
      {sortedGames.length > 0 ? (
        <input
          type="text"
          className="form-control"
          placeholder="🔍Search"
          defaultValue={filterText}
          ref={inputRef}
          onChange={filterTextHandler}
        />
      ) : (
        <></>
      )}
      <div className="container">
        <div className="row row-padding">
          {filteredGames.map((item) => (
            <React.Fragment key={item.gameId}>
              <Modal
                name={item.gameName}
                okBtn={{
                  fn: () => okButton(item),
                  text: 'playNow',
                }}
                cancelBtn={null}
              >
                <div>Modal Window</div>
              </Modal>
              <div key={item.gameId} className="col-md-4 padding_bottom">
                <div className={item.gameId === '1560c13f-4707-494d-922b-b49dd1dfacf1' ? '' : ''}>
                  <div className="tournament-game-header">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      fill="currentColor"
                      className="bi bi-trophy-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z" />
                    </svg>
                    {t('tournament')}
                  </div>

                  <div className="game_box">
                    <figure>
                      <img src={getGameThumbnail(item)} alt="#" />
                    </figure>
                  </div>
                  <div className="game">
                    <h3>{item.gameName}</h3>
                  </div>
                  <div className="btn-toolbar justify-content-center" role="toolbar">
                    <div className="btn-group" role="group">
                      <Link className="btn-link demo" to={`/demo/${item.gameId}`}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-play-circle icon-margin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M6.271 5.055a.5.5 0 0 1 .52.038l3.5 2.5a.5.5 0 0 1 0 .814l-3.5 2.5A.5.5 0 0 1 6 10.5v-5a.5.5 0 0 1 .271-.445z" />
                        </svg>
                        {t('demoPlay')}
                      </Link>
                      <button
                        className="btn-link play"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        onClick={() => onClick(item)}
                        style={{ color: 'white' }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-database icon-margin"
                          viewBox="0 0 16 16"
                        >
                          <path d="M4.318 2.687C5.234 2.271 6.536 2 8 2s2.766.27 3.682.687C12.644 3.125 13 3.627 13 4c0 .374-.356.875-1.318 1.313C10.766 5.729 9.464 6 8 6s-2.766-.27-3.682-.687C3.356 4.875 3 4.373 3 4c0-.374.356-.875 1.318-1.313ZM13 5.698V7c0 .374-.356.875-1.318 1.313C10.766 8.729 9.464 9 8 9s-2.766-.27-3.682-.687C3.356 7.875 3 7.373 3 7V5.698c.271.202.58.378.904.525C4.978 6.711 6.427 7 8 7s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 5.698ZM14 4c0-1.007-.875-1.755-1.904-2.223C11.022 1.289 9.573 1 8 1s-3.022.289-4.096.777C2.875 2.245 2 2.993 2 4v9c0 1.007.875 1.755 1.904 2.223C4.978 15.71 6.427 16 8 16s3.022-.289 4.096-.777C13.125 14.755 14 14.007 14 13V4Zm-1 4.698V10c0 .374-.356.875-1.318 1.313C10.766 11.729 9.464 12 8 12s-2.766-.27-3.682-.687C3.356 10.875 3 10.373 3 10V8.698c.271.202.58.378.904.525C4.978 9.71 6.427 10 8 10s3.022-.289 4.096-.777A4.92 4.92 0 0 0 13 8.698Zm0 3V13c0 .374-.356.875-1.318 1.313C10.766 14.729 9.464 15 8 15s-2.766-.27-3.682-.687C3.356 13.875 3 13.373 3 13v-1.302c.271.202.58.378.904.525C4.978 12.71 6.427 13 8 13s3.022-.289 4.096-.777c.324-.147.633-.323.904-.525Z" />
                        </svg>
                        {t('realPlay')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
